<template>
<div class="testmonial">
  <div class="testimonial-title">
    <img class="union-img" src="./../../assets/imgs/pc/homepage/front-union.png" alt="" >
    {{ $t("testimonials.client_testimonials") }}
    <img class="union-img" src="./../../assets/imgs/pc/homepage/end-union.png" alt="">
  </div>
  <div class="testmonial-content">
    <!-- <div class="leftarrow" @click="clickLeft()">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.12" cx="18" cy="18" r="18" fill="black"/>
      <path d="M19.1366 13.746L15.2576 17.742C15.1874 17.8123 15.1523 17.8963 15.1523 17.994C15.1523 18.0918 15.1874 18.1796 15.2576 18.2576L19.1366 22.2536C19.2069 22.3161 19.2889 22.3474 19.3826 22.3474C19.4764 22.3474 19.5584 22.3123 19.6286 22.242C19.6989 22.1718 19.734 22.0898 19.734 21.996C19.734 21.9023 19.6989 21.8203 19.6286 21.75L15.9724 18L19.6286 14.25C19.6989 14.1798 19.734 14.0978 19.734 14.004C19.734 13.9103 19.6989 13.8283 19.6286 13.758C19.5584 13.6878 19.4764 13.6526 19.3826 13.6526C19.2889 13.6526 19.2069 13.6839 19.1366 13.7464V13.746Z" fill="white"/>
      </svg>
    </div> -->
   <!-- <div class="card-place">
    <div class="main-card-section trans" id="div" v-for="(item,index) in itemShowed" @mouseover="suspend" @mouseleave="autoTest" :key='item.companyName'>
      <div :class="{leftCard:index==0,rightCard:index==2}"></div>
      <div class="brand-name-section">
        <div class="logo">
          <img class="logo-pic" :src="item.logo" alt="">
        </div>
        <div class="company-name">
          <div class="name">{{item.companyName}}</div>
          <div class="author">{{item.author}}</div>
        </div>
        <div class="star-section">
          <img src="./../../assets/imgs/pc/homepage/star.png" class="star" alt="">
        </div>
      </div>
      <div class="comment-section" style="white-space: pre-wrap;" v-html="item.comment"></div>
    </div>
    </div> -->


    <swiper class="swiper" :options="swiperOption" :auto-update="true" style="height:100%;" ref="mySwiper">
      <swiper-slide v-for="(item,index) in cardInfo" :key='item.companyName'>
        <div class="main-card-section">
          <div class="brand-name-section">
            <div class="logo">
              <img class="logo-pic" :src="item.logo" alt="">
            </div>
            <div class="company-name">
              <div class="name">{{item.companyName}}</div>
              <div class="author">{{item.author}}</div>
              
            </div>
            <div class="star-section">
              <img src="./../../assets/imgs/pc/homepage/star.png" class="star" alt="">
            </div>
            
          </div>
          <div class="comment-section" style="white-space: pre-wrap;" v-html="item.comment"></div>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination" style="bottom:0"></div> -->
      <!-- <div class="leftarrow  swiper-button-prev" slot="button-prev">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.12" cx="18" cy="18" r="18" fill="black"/>
        <path d="M19.1366 13.746L15.2576 17.742C15.1874 17.8123 15.1523 17.8963 15.1523 17.994C15.1523 18.0918 15.1874 18.1796 15.2576 18.2576L19.1366 22.2536C19.2069 22.3161 19.2889 22.3474 19.3826 22.3474C19.4764 22.3474 19.5584 22.3123 19.6286 22.242C19.6989 22.1718 19.734 22.0898 19.734 21.996C19.734 21.9023 19.6989 21.8203 19.6286 21.75L15.9724 18L19.6286 14.25C19.6989 14.1798 19.734 14.0978 19.734 14.004C19.734 13.9103 19.6989 13.8283 19.6286 13.758C19.5584 13.6878 19.4764 13.6526 19.3826 13.6526C19.2889 13.6526 19.2069 13.6839 19.1366 13.7464V13.746Z" fill="white"/>
        </svg>
      </div> -->
    </swiper>




   <!-- <div class="rightarrow" @click="clickRight()">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.12" cx="18" cy="18" r="18" fill="black"/>
    <path d="M16.0054 13.746C15.9351 13.8242 15.9 13.9102 15.9 14.004C15.9 14.0977 15.9351 14.1797 16.0054 14.25L19.6616 18L16.0054 21.75C15.9351 21.8202 15.9 21.9022 15.9 21.996C15.9 22.0897 15.9351 22.1717 16.0054 22.242C16.0756 22.3122 16.1576 22.3474 16.2514 22.3474C16.3451 22.3474 16.4271 22.3161 16.4974 22.2536L20.3764 18.2576C20.4466 18.1874 20.4817 18.1014 20.4817 17.9996C20.4817 17.8979 20.4466 17.8119 20.3764 17.7416L16.4974 13.7456C16.4271 13.6831 16.3451 13.6519 16.2514 13.6519C16.1576 13.6519 16.0756 13.6831 16.0054 13.7456V13.746Z" fill="white"/>
    </svg>
   </div> -->
  </div>

  <!-- <div class="dot-section">
    <div v-for="(dot,index) in cardInfo" :key="index" class="">
      <span v-if="index===position" class="dogBlue">
      </span>
      <span v-else class="dogWhite" @click="changePos(index)">
      </span>
    </div>
  </div> -->
  <div class="swiper-button-prev" slot="button-prev">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.12" cx="18" cy="18" r="18" fill="black"/>
      <path d="M19.1366 13.746L15.2576 17.742C15.1874 17.8123 15.1523 17.8963 15.1523 17.994C15.1523 18.0918 15.1874 18.1796 15.2576 18.2576L19.1366 22.2536C19.2069 22.3161 19.2889 22.3474 19.3826 22.3474C19.4764 22.3474 19.5584 22.3123 19.6286 22.242C19.6989 22.1718 19.734 22.0898 19.734 21.996C19.734 21.9023 19.6989 21.8203 19.6286 21.75L15.9724 18L19.6286 14.25C19.6989 14.1798 19.734 14.0978 19.734 14.004C19.734 13.9103 19.6989 13.8283 19.6286 13.758C19.5584 13.6878 19.4764 13.6526 19.3826 13.6526C19.2889 13.6526 19.2069 13.6839 19.1366 13.7464V13.746Z" fill="white"/>
    </svg>
  </div>
  <div class="swiper-button-next" slot="button-next">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.12" cx="18" cy="18" r="18" fill="black"/>
      <path d="M16.0054 13.746C15.9351 13.8242 15.9 13.9102 15.9 14.004C15.9 14.0977 15.9351 14.1797 16.0054 14.25L19.6616 18L16.0054 21.75C15.9351 21.8202 15.9 21.9022 15.9 21.996C15.9 22.0897 15.9351 22.1717 16.0054 22.242C16.0756 22.3122 16.1576 22.3474 16.2514 22.3474C16.3451 22.3474 16.4271 22.3161 16.4974 22.2536L20.3764 18.2576C20.4466 18.1874 20.4817 18.1014 20.4817 17.9996C20.4817 17.8979 20.4466 17.8119 20.3764 17.7416L16.4974 13.7456C16.4271 13.6831 16.3451 13.6519 16.2514 13.6519C16.1576 13.6519 16.0756 13.6831 16.0054 13.7456V13.746Z" fill="white"/>
    </svg>
  </div>
  
</div>
  


</template>


<script>
export default {
  created(){
    this.itemShowed = [...this.cardInfo.slice(0,3)];
    this.autoTest();
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
    // new Swiper ('.swiper-container', {
    //   loop: true,
    //   // 如果需要分页器
    //   pagination: '.swiper-pagination',
    //   // 如果需要前进后退按钮
    //   nextButton: '.swiper-button-next',
    //   prevButton: '.swiper-button-prev',
    //   // 如果需要滚动条
    //   scrollbar: '.swiper-scrollbar',
    // });
  },
  props:[],
  data(){
    return {
      
      swiperOption: {
        navigation: {
          prevEl: '.swiper-button-prev', //左侧按钮
          nextEl: '.swiper-button-next' //右侧按钮
        },
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        initialSlide:9,
        // autoplay:true,
        speed:2000,
        slidesPerView:3,
        loop: true, //循环吗
        autoplay: {
          delay: 5000, //自动切换的时间间隔，单位ms
          // stopOnLastSlide: false, //当切换到最后一个slide时停止自动切换
          // stopOnLastSlide: true, //如果设置为true，当切换到最后一个slide时停止自动切换。
          disableOnInteraction: true, //用户操作swiper之后，是否禁止autoplay。
          // reverseDirection: true, //开启反向自动轮播。
          // waitForTransition: true, //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
        },
        autoHeight: true,
        loopFillGroupWithBlank: true, //刷一屏3个，但是总共只有8个。第九个用不用补位？
      },
      isSuspend:false,
      itemShowed:[],
      position:10,
      timer:'',
      itemArray:[],
      // pageList:1,
      cardInfo: 
      [
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/coronation.png'),
          companyName: 'Coronation',
          author: 'Anita',
          comment: 'I have been using Arcanite for the past 4 months. As a Channel Manager, Arcanite is perfect app to manage channels, share marketing material and maintain stocks. William and his team are professional and helpful through the whole journey. They made onboarding process easy and smooth. Our channels was trained by them which freed my time to focus on selling. Whenever there is an issue, William’s team always have my back. The continuous improvement of functions and UI makes it more user-friendly and powerful selling tool. Highly recommended to everyone in property industry.',
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/creation.png'),
          companyName: 'Creation Homes',
          author: 'Shelby',
          comment: 'Our experience with Arcanite has been seamless from customisation and development to training and on-boarding. The Arcanite team have gone above and beyond to develop the right solution for our business needs and deliver a platform that caters for our ever – changing project and platform requirements. Creation Homes NSW looks forward to our ongoing relationship with Arcanite in transforming how we do business as a project marketer.',
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/mcgrath.png'),
          companyName: 'McGrath Projects',
          author: 'Louise Drummond',
          comment: "The Projects space is a unique one within the Real Estate industry and not many off the shelf CRM's can cater to all the needs of Projects. </br> </br>The team at Arcanite are developing and providing a unique solution to resolve this need, they are working collaboratively with our team to make changes and adjustments to their product. They are hungry for feedback and eager to make their product user friendly, productive and versatile. The IT and admin support from every team member has been excellent.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/quarter.png'),
          companyName: 'QUARTER ACRE',
          author: 'Chirag Balani',
          comment: "We settled with ARC after looking around for an inventory management app for a long time and we are very happy that we decided to proceed with ARC. It is much more then an inventory management app, very easy to use, intuitive and feature are always growing. ARC has a very close-knit team and very supportive. We would highly recommend ARC to anyone looking for an inventory management app.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/sh.png'),
          companyName: 'SH International',
          author: 'Narikki Kudinar-Kwee',
          comment: "ARC has provided SH with greater control and management of our project sales. With its ease of use for both the agent and developer we are able to implement this quickly and without fuss. ARC is now an integral part of all our current and future projects.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/ozy.png'),
          companyName: 'Ozy Homes',
          author: '',
          comment: "Ozyhomes is now using Arcanite as our CRM. We are very impressed with the platform as it has great customer contact management along with backend agent management that supports sales management and precisely assists our business to quantify lead source capture and opportunities. The Arcanite Team behind the platform is supportive and responsive making it easy to integrate this platform into our business.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/tallpopie.png'),
          companyName: 'Tallpopie',
          author: 'Jennifer Hawke',
          comment: "After months of searching for a CRM solution that catered to off-the-plan sales it was great to come across Arcanite. The software provides a hub for all project collateral, advises of real time reservations and sales - ensuring both our internal and external agents are working with the most up to date information. It is refreshing to work with a team who are excited to advance their software and actively engage with the consumer to ensure all needs are being met. We look forward to an ongoing relationship with the Arcanite team, Jane & Harvey in particular are an absolute delight!",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/linic.png'),
          companyName: 'Linic Property Group',
          author: 'Juliet Chen',
          comment: "It is the first time working with Arcanite to produce virtual tours of our off-the-plan apartments and the team has been an absolute pleasure to deal with. They were patient throughout the whole process, the end results of the renders were of high quality, and all the feedback we've received since has been amazing. I highly recommend their VR service, it has proven very beneficial for selling pre-sale properties.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/kmac.png'),
          companyName: 'Kmac Group',
          author: 'Anna Mingoy',
          comment: "We've used some CRMs and Sales Portals before but have always been boxed into whatever process and system the Software Developer has created. Getting help can also be a pain due to some people not being able to explain anything other than in “tech language”. </br> </br>What we love about Arcanite is not only how easy it is to use and navigate their system, but most importantly the team is very accommodating, and they are always available when we need further help.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/liveProperty.png'),
          companyName: 'Live Property Marketing',
          author: 'Manny and Khay',
          comment: "ARC has been an amazing system to use, has halved the time for admin work and saved us a whole salary on an admin assistant. The fact that you can use this on your phone, Ipad and computer is really helpful. It looks sophisticated and it's easy to use. The backend is also amazing as this shows us how the business is doing in sales and the projects. </br> </br>Would rate this a 20/10 if we could.",
          // index: 0,
        },
        {
          logo: require('./../../assets/imgs/pc/testimonialCard/family.png'),
          companyName: 'Family First',
          author: 'Lawrence',
          comment: "Arcanite has the best lead generation system. Arcanite has helped grow my business and referral partners. The pre qualification system helps me to manage and prioritise leads. Arcanite is a perfect fit for my business. Highly recommended.",
          // index: 0,
        },
      ],
    }
  },
  // props:{
  //   CardInfo:{
  //     type:object,
  //     required:true,
  //   }
  // },
  methods:{
    autoTest(){
      this.timer = setInterval(this.clickRight, 5000);
      this.itemArray = Array.from(document.getElementsByClassName('trans'))
      this.itemArray[0].style.animation = "fadeIn 5s ease-In" 
    },
    suspend(){
      clearInterval(this.timer);
      // Array.from(document.getElementById("div")).forEach(element => {
      //   element.classList.remove("trans");
      // })
      this.itemArray = Array.from(document.getElementsByClassName('trans'))
      this.itemArray[0].style.animation = "staticIn";
    },
    stopSwiper(){
      // 存放鼠标悬浮时的transform属性
      this.nextTransForm = this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform;
      // 轮播图原本应移动到的位置
      let nextTransPosition = -1 * parseInt(this.nextTransForm.split("px")[0].split("translate3d(")[1]);
      // // 鼠标悬浮时时轮播图位置
      let nowTransPosition = -1 * parseInt(window.getComputedStyle(this.$refs.mySwiper.$swiper.$wrapperEl[0], false)["transform"].split("1, ")[2].split(",")[0]);
 
      // 存放鼠标悬浮时轮播图的真实transform属性（非行内属性）
      let nowTransForm = window.getComputedStyle(this.$refs.mySwiper.$swiper.$wrapperEl[0], false)["transform"];
 
      // 计算轮播图从暂停位置移动到原本应到的位置所用的时间（150是我自定义的每个slide的宽度）
      this.nextTime = 4000 * ((nextTransPosition - nowTransPosition) / 150);
 
      // 改变行内transform属性
      this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform = nowTransForm;
      this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transitionDuration = "0ms";
 
      this.$refs.mySwiper.$swiper.autoplay.stop();
    },
    startSwiper(){
      this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transform = this.nextTransForm;
      this.$refs.mySwiper.$swiper.$wrapperEl[0].style.transitionDuration = this.nextTime + "ms";
      this.$refs.mySwiper.$swiper.autoplay.start();
    },
    // changePos(index){
    //   this.itemShowed = [...this.cardInfo[index]]
    //   this.position = index;
    // },
    clickLeft(){
      if(this.position==0){
        this.itemShowed.length = 2;
        this.itemShowed.unshift(this.cardInfo[this.cardInfo.length-1])
        this.position=this.cardInfo.length-1; //8
      }else{
        this.position--;
        this.itemShowed.length = 2;
        this.itemShowed.unshift(this.cardInfo[this.position])
      }
    },
    clickRight(){
      if(this.position == 6){
        this.position = 7;
        this.itemShowed.shift();
        this.itemShowed.push(this.cardInfo[0]);
      }else if(this.position == 7){
        this.position = 8;
        this.itemShowed.shift();
        this.itemShowed.push(this.cardInfo[1]);
      }else if(this.position == 8){
        this.itemShowed.shift();
        this.itemShowed.push(this.cardInfo[2]);
        this.position = 0;
      }
      else{
        this.position++;
        this.itemShowed.shift();
        this.itemShowed.push(this.cardInfo[this.position+2]);
      }
    },
  }
  
}
</script>


<style lang="scss" scoped>



.testmonial{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 50px;
}

.testimonial-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  font-family: 'Poppins-bold';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
  color: #062440;
  flex: none;
  margin-bottom: 122px;
  .union-img{
    width: 74px;
    height: 63px;
  }
  }

.testmonial-content{
  // background-image: url('./../../assets/imgs/pc/testimonialCard/backgroundCard.png');
  // background-size: contain;
  // background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  overflow: hidden;
  // position: relative;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
  // align-items: center;
  // gap:20px;
}


.card-place{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.main-card-section{
  // position: relative;
  z-index: 0;
  width: 500px;
  min-height: 316px;
  // height: 100%;
  padding: 30px;
  // background-color:green;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // gap: 14px;
  background: rgba(255, 255, 255, 0.9);
  /* Light/Neutral/00 */

  border: 1px solid #FFFFFF;
  box-shadow: 0px 18px 99px rgba(24, 144, 255, 0.04), 0px 4.02054px 22.1129px rgba(24, 144, 255, 0.0238443), 0px 1.19702px 6.5836px rgba(24, 144, 255, 0.0161557);
  border-radius: 10px;
  margin: 0;
  // align-items: flex-start;
}

.leftCard{
  position: absolute;
  // left:-150px;
  width: 500px;
  min-height: 316px;
  // border-radius: 5px;
  z-index: 10;
  background: linear-gradient(to right,rgba(255,255,255,1), rgba(255,255,255,0));
  // background: black;
}

.rightCard{
  position: absolute;
  // left:-150px;
  width: 500px;
  min-height: 316px;
  // left:950px;
  z-index: 10;
  // border-radius: 5px;
  background: linear-gradient(to left,rgba(255,255,255,1), rgba(255,255,255,0));
}

.brand-name-section{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom:14px;

  .company-name{
    margin-left: 14px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .name{
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.06px;
    color: #041A2F;
    flex: none;
    order: 0;
    flex-grow: 0;
    white-space: nowrap;

  }

  .author{
    font-family: 'Poppins';
    font-size: 16px;
    line-height: 22px;
    color: #062440;
    flex: none;
    order: 1;
    flex-grow: 0;
    white-space: nowrap;
  }
}


.logo-pic{
  width: 60px;
  height: 60px;
}

.star-section{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

}

.star{
  width:116px;
  height:20px;
}


.comment-section{
  width: 500px;
  // height: 242px;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 22px;
  color: #314455;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  text-align: left;
}

// .leftarrow{
//   position: absolute;
//   top: 60%;
//   left: 1%;
//   z-index: 10;
//  cursor: pointer;
//  &:hover{
//   circle{
//     opacity: 0.24;
//   }
//  }
// }

// .rightarrow{
//  cursor: pointer;
//  position: absolute;
//  top: 60%;
//  right: 1%;
//  z-index: 99;
//  &:hover{
//   circle{
//     opacity: 0.24;
//   }
//  }
// }

.dogBlue{
  width: 38px;
  height: 6px;
  // border-radius: 50%;
  background: #1890ff;
  display: inline-block;
}

.dogWhite{
  cursor: pointer;
  width: 38px;
  height: 6px;
  // border-radius: 50%;
  background: #d8d8d8;
  display: inline-block;
}

.dot-section{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.swiper-button-prev{
  left: 0;
}

.trans{
    animation-name: fadeIn;
    // animation: ease-in;
    animation-duration: 5s;
    animation-timing-function: ease-in;
    // animation-play-state: 
    animation-iteration-count: infinite;

  }

  .card-place:hover{
    .trans{
      animation-name: staticIn;
      // animation: ease-in;
      animation-duration: 5s;
      // animation-timing-function: ease-in;
      // animation-play-state: 
      animation-iteration-count: infinite;
    }
  }


.swiper-slide-active{
  margin-left: 30px;
}

.swiper-container{
  // position: absolute;
}

.swiper-button-prev{
  // width: 10px;
  // height: 10px;
  // background: #314455;
  // border-radius: 50%;
  // color: #ffffff;
  // display:
  // opacity: 0.12;
  position: absolute;
  left: 1%;
  top:80%;
  svg{
    position: absolute;
    width: 36px;
    height: 36px;
    // z-index: 1000;
  }
  &::after{
    content: none;
  }
  &:hover{
  circle{
    opacity: 0.24;
  }
 }
  // position: relative;
  // top: -50%;
}

.swiper-button-next{
  // width: 10px;
  // height: 10px;
  // background: #314455;
  // border-radius: 50%;
  // color: #ffffff;
  // display:
  // opacity: 0.12;
  position: absolute;
  right: 1%;
  top:80%;
  svg{
    position: absolute;
    width: 36px;
    height: 36px;
    // z-index: 1000;
  }
  &::after{
    content: none;
  }
  &:hover{
  circle{
    opacity: 0.24;
  }
 }
  // position: relative;
  // top: -50%;
}


@keyframes fadeIn {
    0% {
      // opacity: 0;
      transform: translateX(100px);
    }

    30% {
      // opacity: 1;
      transform: translateX(0);
    }

    70% {
      // opacity: 1;
      transform: translateX(0);
    }

    100% {
      // opacity: 0;
      transform: translateX(-200px);
    }
  }

  @keyframes staticIn {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

</style>
